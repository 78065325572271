import axios, { AxiosResponse } from 'axios';
import config from '../config';
import FileDownloadModel from '../models/FileDownloadModel';
import FileEnum from '../constants/enums/FileEnum';

const hindcastPredictionURI = config.SERVER_URL + config.BASE_PATH + config.PREDICTION_PATH;
const umiPredictionURI = `${config.SERVER_URL + config.BASE_PATH + config.PREDICTION_PATH}/umi`;
const suraPredictionURI = `${config.SERVER_URL + config.BASE_PATH + config.PREDICTION_PATH}/sura`;
const datesURI = `${config.SERVER_URL + config.BASE_PATH + config.PREDICTION_PATH}/dates`;

interface UmiImages {
  precip: {
    threeMonth: string;
    sixMonth: string;
  };
  temp: {
    threeMonth: string;
    sixMonth: string;
  };
}

export type { UmiImages };
export async function getHindcastPredictionByCoordinates(latitude: number, longitude: number, predictionType: string) {
  try {
    const response = await axios.get(hindcastPredictionURI, {
      params: {
        latitude,
        longitude,
        type: predictionType,
      },
    });
    // Handle the response from the backend
    downloadHindcastFromRes(response.data as FileDownloadModel);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function postHindcastPredictionFileUpload(file: File, predictionType: string): Promise<void> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(hindcastPredictionURI, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        type: predictionType,
      },
    });
    downloadHindcastFromRes(response.data as FileDownloadModel);
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

export async function getUmiPrediction(fileType: FileEnum) {
  try {
    const response = await getPredictionRaw(umiPredictionURI, fileType);
    // Handle the response from the backend; return strongly typed object
    return response.data as Array<JSON>;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}



export async function getPredictionDates() {
  try {
    const response = await getPredictionRaw(datesURI, FileEnum.SURA_FORECAST);
    // Handle the response from the backend; return strongly typed object
    return response.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function downloadUmiPrediction(fileType: FileEnum) {
  try {
    const response = await getPredictionRaw(umiPredictionURI, fileType, 'application/csv');
    downloadFileFromRes(response.data, 'enso_data.csv');
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function getSuraPrediction() {
  try {
    const uri = `${suraPredictionURI}?type=${FileEnum.SURA_FORECAST}`;
    const response = await axios.get(uri, {
      responseType: 'arraybuffer',
      decompress: true,
      headers: {
        Accept: 'application/gzip',
      },
    });

    console.log('Response Headers:', response.headers);
    
    if (response.status === 200) {
      const buffer = response.data;

      // Convert the decompressed data to a string
      return new TextDecoder('utf-8').decode(buffer);
    } else {
      throw new Error('Failed to fetch Sura prediction');
    }
  } catch (error) {
    console.error('Error fetching TopoJSON file:', error);
    throw error;
  }
}


export async function getPredictionRaw(uri: string, fileType: FileEnum, accept: string = 'application/json', buffer: boolean = false): Promise<AxiosResponse> {
  try {
    const options: any = { params: { type: fileType }, headers: { Accept: accept } };
    if (buffer) {
      options.responseType = 'arraybuffer';
    } 
    return await axios.get(uri, options);    
  } catch (error) {
    console.error('Error fetching file:', error);
    throw error;
  }
}

function base64ToBinary(base64String: string) {
  const binaryString = atob(base64String);
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  return byteArray;
}

async function downloadHindcastFromRes(response: FileDownloadModel) {
  const resBin = base64ToBinary(response.fileData);
  return downloadFileFromRes(resBin, response.fileName);
}

async function downloadFileFromRes(response: any, fileName: string) {
  const blob = new Blob([response]);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}

export const fetchAllUmiImages = async (): Promise<UmiImages> => {
  try {
    console.log('Fetching all UMI images');
    
    const responses = await Promise.all([
      getPredictionRaw(umiPredictionURI, FileEnum.TEMP_3M, 'image/png', false),
      getPredictionRaw(umiPredictionURI, FileEnum.TEMP_6M, 'image/png', false),
      getPredictionRaw(umiPredictionURI, FileEnum.PRECIP_3M, 'image/png', false),
      getPredictionRaw(umiPredictionURI, FileEnum.PRECIP_6M, 'image/png', false)
    ]);

    if (!responses.every(r => r.status === 200)) {
      throw new Error('Failed to fetch one or more images');
    }

    const createImageBlob = (response: AxiosResponse): Blob => {
      // Handle structured response from backend with base64 encoded data
      if (response.data?.body && response.data?.isBase64Encoded) {
        const binary = base64ToBinary(response.data.body);
        const blob = new Blob([binary], { type: 'image/png' });
        return blob;
      }
      
      // Handle ArrayBuffer response (fallback)
      const blob = new Blob([response.data], { type: 'image/png' });
      return blob;
    };

    // Create Blobs from either base64 or ArrayBuffer responses
    const [temp3m, temp6m, precip3m, precip6m] = responses.map(createImageBlob);
    
    return {
      temp: {
        threeMonth: URL.createObjectURL(temp3m),
        sixMonth: URL.createObjectURL(temp6m)
      },
      precip: {
        threeMonth: URL.createObjectURL(precip3m),
        sixMonth: URL.createObjectURL(precip6m)
      }
    };
  } catch (error) {
    console.error('Error fetching UMI images:', error);
    throw error;
  }
};

