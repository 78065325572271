import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Card, CardContent } from '@mui/material';
import { getUmiPrediction } from '../network/prediction';
import umiFileEnum from '../constants/enums/FileEnum';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[];
}

// Add module-level cache
let cachedData: ChartData | null = null;

const BarChart = () => {
  const [chartData, setChartData] = useState<ChartData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      // If we have cached data, use it
      if (cachedData) {
        setChartData(cachedData);
        return;
      }

      try {
        const jsonData = await getUmiPrediction(umiFileEnum.FORECAST);
        console.trace('Fetched data:', jsonData);

        const labels = jsonData.map((item: any) => {
          const date = new Date(item.Date);
          return date.toLocaleDateString('default', { month: 'long', year: 'numeric', timeZone: 'UTC' });
        });

        const probLaNina = jsonData.map((item: any) => item.Prob_La_Nina * 100);
        const probNeutral = jsonData.map((item: any) => item.Prob_Neutral * 100);
        const probElNino = jsonData.map((item: any) => item.Prob_El_Nino * 100);

        const newData = {
          labels,
          datasets: [
            {
              label: 'La Niña',
              data: probLaNina,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              borderWidth: 1,
            },
            {
              label: 'Neutral',
              data: probNeutral,
              backgroundColor: 'rgba(128,128,128,0.4)',
              borderColor: 'rgba(128,128,128,1)',
              borderWidth: 1,
            },
            {
              label: 'El Niño',
              data: probElNino,
              backgroundColor: 'rgba(255,0,0,0.4)',
              borderColor: 'rgba(255,0,0,1)',
              borderWidth: 1,
            },
          ],
        };

        // Cache the data
        cachedData = newData;
        setChartData(newData);
      } catch (error) {
        console.error('Error fetching the data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only on mount

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            const value = Math.round(tooltipItem.raw as number);
            return `${value}%`;
          },
        },
      },
    },
    layout: {
      padding: {
        bottom: 0, // Adjust padding to move the slider closer
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Probability (%)',
        },
      },
    },
  };

  return (
    <Card>
      <CardContent>{chartData ? <Bar data={chartData} options={options} /> : 'Loading...'}</CardContent>
    </Card>
  );
};

export default BarChart;
