import React, { useEffect, useState, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions,
} from 'chart.js';
import { Card, CardContent, Slider, Box, Checkbox, FormControlLabel, Button } from '@mui/material';
import { downloadUmiPrediction, getUmiPrediction } from '../network';
import FileEnum from '../constants/enums/FileEnum';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, TimeScale);

const formatDateToMonthYear = (dateString: string): string => {
  const date = new Date(dateString);
  if (Number.isNaN(date.getTime())) return 'Invalid Date';
  return date.toLocaleDateString('default', { month: 'long', year: 'numeric', timeZone: 'UTC' });
};

interface ChartData {
  labels: string[];
  data: number[];
  trueData: number[];
  combinedLabels: string[];
  trueLabels: string[];
}

let cachedData: ChartData | null = null;

const LineChart: React.FC = () => {
  const [range, setRange] = useState<number[]>([0, 0]);
  const [showPastData, setShowPastData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    data: [],
    trueData: [],
    combinedLabels: [],
    trueLabels: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      // If we have cached data, use it
      if (cachedData) {
        setChartData(cachedData);
        const forecastStartIndex = cachedData.combinedLabels.findIndex(
          (label) => label === cachedData?.labels[0]
        );
        setRange([forecastStartIndex, cachedData.combinedLabels.length - 1]);
        setIsLoading(false);
        return;
      }

      try {
        const [forecastData, trueData] = await Promise.all([
          getUmiPrediction(FileEnum.NINO_34_FORECAST),
          getUmiPrediction(FileEnum.NINO_TRUE)
        ]);

        const labels = forecastData.map((item: any) => {
          const date = new Date(item.Date);
          return date.toISOString();
        });

        const data = forecastData.map((item: any) => item.Forecast);

        const trueLabels = trueData.map((item: any) => {
          const date = new Date(`${item.time}T00:00:00Z`);
          return date.toISOString();
        });

        const trueDataPoints = trueData.map((item: any) => parseFloat(item.True));

        const combinedLabels = Array.from(new Set([...trueLabels, ...labels])).sort(
          (a, b) => new Date(a).getTime() - new Date(b).getTime(),
        );

        const newData = {
          labels,
          data,
          trueData: trueDataPoints,
          combinedLabels,
          trueLabels,
        };

        // Cache the data
        cachedData = newData;
        
        setChartData(newData);
        const forecastStartIndex = combinedLabels.findIndex((label) => label === labels[0]);
        setRange([forecastStartIndex, combinedLabels.length - 1]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only on mount

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setRange(newValue as number[]);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPastData(event.target.checked);

    if (event.target.checked) {
      const pastStartIndex = chartData.combinedLabels.findIndex((label) => label === chartData.trueLabels[0]);
      setRange([pastStartIndex, range[1]]);
    } else {
      const forecastStartIndex = chartData.combinedLabels.findIndex((label) => label === chartData.labels[0]);
      setRange([forecastStartIndex, range[1]]);
    }
  };

  const handleDownloadClick = () => {
    downloadUmiPrediction(FileEnum.ENSO_ALL_DATA);
  };

  const data = {
    labels: chartData.combinedLabels.slice(range[0], range[1] + 1).map(formatDateToMonthYear), // Format labels
    datasets: [
      {
        label: 'Past Data',
        data: chartData.trueData.concat(new Array(chartData.data.length).fill(null)).slice(range[0], range[1] + 1),
        borderColor: '#A9A9A9',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        fill: false,
        pointRadius: 1,
        pointHoverRadius: 3,
      },
      {
        label: 'Forecast',
        data: new Array(chartData.trueData.length)
          .fill(null)
          .concat(chartData.data)
          .slice(range[0], range[1] + 1),
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        fill: true,
        pointRadius: 2,
        pointHoverRadius: 3,
      },
      {
        label: 'Connection',
        data: (() => {
          const trueDataLength = chartData.trueData.length;
          const forecastDataLength = chartData.data.length;

          if (trueDataLength > 0 && forecastDataLength > 0) {
            return [
              ...new Array(trueDataLength - 1).fill(null),
              chartData.trueData[trueDataLength - 1],
              chartData.data[0],
              ...new Array(forecastDataLength - 1).fill(null),
            ];
          }
          return [];
        })().slice(range[0], range[1] + 1),
        borderColor: '#A9A9A9',
        borderDash: [5, 5],
        fill: false,
        pointRadius: 0,
        showLine: true,
        spanGaps: true,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          filter(legendItem) {
            return legendItem.text !== 'Connection';
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: false,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: false,
          text: 'Forecast',
        },
      },
    },
  };

  return (
    <Card sx={{ height: '80%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          pb: 2,
          marginTop: '30px',
        }}
      >
        <Slider
          value={range}
          onChange={handleRangeChange}
          valueLabelDisplay="auto"
          min={0}
          max={chartData.combinedLabels.length - 1}
          marks={chartData.combinedLabels.map((date, index) => {
            const formattedDate = formatDateToMonthYear(date);
            return { value: index, label: formattedDate };
          })}
          valueLabelFormat={(index) => formatDateToMonthYear(chartData.combinedLabels[index])}
          sx={{
            width: '90%',
            '& .MuiSlider-thumb': {
              color: 'rgba(75,192,192,0.2)',
            },
            '& .MuiSlider-track': {
              color: 'rgba(75,192,192,0.2)',
            },
            '& .MuiSlider-valueLabel': {
              fontSize: '0.75rem',
              transform: 'translateY(-20px) rotate(0deg)',
            },
            '& .MuiSlider-valueLabel[data-index="1"]': {
              transform: 'rotate(180deg) translateY(100%)',
            },
            '& .MuiSlider-rail': {
              color: 'rgba(75,192,192,0.2)',
            },
            '& .MuiSlider-markLabel': {
              fontSize: '0rem',
              transform: 'rotate(270deg)',
              transformOrigin: 'right',
              '-webkit-transform': 'rotate(270deg)',
              '-webkit-transform-origin': 'right',
            },
          }}
        />
      </Box>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControlLabel
            control={<Checkbox checked={showPastData} onChange={handleCheckboxChange} color="primary" />}
            label="Show Past Data"
          />
          <Button
            variant="outlined"
            onClick={handleDownloadClick}
            sx={{ color: 'rgba(75,192,192,1)', borderColor: 'rgba(75,192,192,1)' }}
          >
            Download
          </Button>
        </Box>
        <Line data={data} options={options} />
      </CardContent>
    </Card>
  );
};

export default LineChart;
